<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Customers from '@/services/Customers';
import modalEditGroup from '@/components/modals/customers/modalEditGroup';
import modalAddMembers from '@/components/modals/customers/modalAddGroupMembers';




import Swal from "sweetalert2";

/**
 * Profile component
 */
export default {
  components: {Layout,PageHeader ,modalEditGroup, modalAddMembers},
  page: {
    title: "Group details",
    meta: [
      {
        name: "details",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      blurConfig: {
        isBlurred: false, // activate and deactivate based on a boolean value and also provide a config
        opacity: 0.1,
        filter: 'blur(1.5px)',
        transition: 'all .3s linear'
      },
      title: "Group details",
      items: [
        {
          text: "Groups",
        },
        {
          text: "Details",
          active: true,
        },
      ],
      groupInfo: {},
      groupMembers: [],
      tableData: [],
      filterData: true,
      isBusy: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "email",
      sortDesc: false,
      error: null,
      item: {},
      modalData: {},
      customer_id: '',
      toggleProfile: false,
      showLoader:false,
      group_id: null,
      fields: [
        {
          key: "first_name",
          label: "First Name",
          sortable: true,
        },
        {
          key: "last_name",
          label: "Last Name",
          sortable: true,
        },
        {
          key: "middle_name",
          label: "Middle Name",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "action",
          label: "Action",
          sortable: false,
        }

      ],
    };
  },
  async mounted() {
    this.group_id = this.$route.params.group_id;
    this.getGroupsDetails()
    await this.getMembers()
  },
  computed: {
    filteredFields() {
      if (this.groupInfo.type === 'static') {
        return [...this.fields, {
          key: "action",
          label: "Action",
          sortable: false,
        }];
      } else {
        return this.fields.filter(field => field.key !== 'action');
      }
    }
  },

  methods: {
    getGroupsDetails() {
      this.showLoader = true;

      Customers.getGroupsDetails(this.$route.params.group_id)
          .then(response => {
            let groupInfo = response.data.data;
            if(groupInfo[0]){
              this.groupInfo = groupInfo[0];
            }else{
              this.groupInfo = {}
            }
          })
          .catch(error => {
            this.error = error.response.data.error ? error.response.data.error : "";
          })
          .finally(() => {
            this.showLoader = false;
          });
    },
    removeGroup(group_id){
      Swal.fire({
        title: "Remove this group?",
        text: "This Group and all members will be removed!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Remove!",
      }).then((result) => {
        if (result.value) {
          try {
            Customers.deleteGroup(group_id)
                .then((response) => {
                  const res = response.data ? response.data : false;
                  const error = response.data.error ? response.data.error : 'Failed';
                  if(res){
                    this.getGroupsDetails()
                    Swal.fire("Remove this group", "Group is successfully removed!", "success")
                        .then(()=> {
                          this.$router.push({ name: 'groups' });
                        });
                  }else{
                    Swal.fire("Fail!", error, "warning");
                  }
                })
          }catch(error){
            this.error = error.response.data.error ? error.response.data.error : "";
          }
        }
      });
    },
    async removeMember(group_id, user_id) {
      Swal.fire({
        title: "Remove this member?",
        text: "This Member will be removed!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Remove!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await Customers.deleteMember(group_id, user_id);
            const res = response.data ? response.data : false;
            const error = response.data.error ? response.data.error : 'Failed';
            if (res) {
              await this.getMembers(); // Fetch updated member list
              Swal.fire("Remove this member", "Member is successfully removed!", "success");
            } else {
              Swal.fire("Fail!", error, "warning");
            }
          } catch (error) {
            this.error = error.response.data.error ? error.response.data.error : "";
          }
        }
      });
    },

    async getMembers() {
      Customers.getMembers(this.$route.params.group_id)
          .then(response => {
            let groupMembers = response.data
            this.tableData = groupMembers.members
            this.totalRows = this.tableData.length
          })
          .catch(error => {
            this.error = error.response.data.error ? error.response.data.error : "";
          });
    },

    callModalEditGroup(data) {
      this.$bvModal.show("group_edit");
      this.modalData = data;
    },
    callModalAddMembers(data) {
      this.$bvModal.show("add_groups");
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    toggle() {
      this.toggleProfile = !this.toggleProfile
    },
  },


};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-4">
      <div v-if="!toggleProfile" class="col-xl-4">
        <div class="card h-100">
          <div class="card-body" v-if="!showLoader">
            <div class="text-center">
              <b-dropdown
                  class="float-end"
                  variant="white"
                  right
                  menu-class="dropdown-menu-end"
                  toggle-class="font-size-16 text-body p-0">
                <template v-slot:button-content>
                  <i class="uil uil-ellipsis-v"></i>
                </template>
                <a class="dropdown-item"
                   href="javascript:void(0);"
                   @click="callModalEditGroup(groupInfo)">Edit</a>
                <a class="dropdown-item d-none" href="#">Remove</a>
              </b-dropdown>

              <i class="uil uil-angle-left float-start font-size-20 text-body btn p-0"
                 @click="toggle"></i>
              <div class="clearfix"></div>

              <h3 class="mt-3 mb-1">{{ groupInfo.name }}</h3>

              <div class="mt-2">

              </div>
            </div>

            <hr class="my-2" />

              <div class="row">
                <div class="col-12 col-md-12 float-start">
                  <h5>Group Information</h5>
                </div>
              </div>
              <div class="mt-3">
                <p class="mb-2 text-strong">Description:</p>
                <p class="font-size-16 mb-2 justify-content-between">{{ groupInfo.description }}</p>
              </div>
            <div class="table-responsive mb-0">
              <div class="mt-3">
                <p class="mb-2 text-strong">Type:</p>
                <p class="font-size-16 mb-2 justify-content-between">{{ groupInfo.type }}</p>
              </div>
              <div class="mt-3">
                <p class="mb-2 text-strong">Provider:</p>
                <p class="font-size-16 mb-2 justify-content-between">{{ groupInfo.provider }}</p>
              </div>
              <div class="mt-3">
                <p class="mb-2 text-strong">Created By:</p>
                <p class="font-size-16 mb-2 justify-content-between">{{ groupInfo.created_by }}</p>
              </div>
              <b-button
                  v-if="groupInfo.provider === null || groupInfo.provider === ''"
                  class="mb-2 d-block m-auto mwb-11"
                  variant="danger"
                  title="Delete Group"
                  @click="removeGroup(groupInfo.group_id)"
              >
                Delete Group
              </b-button>


            </div>

          </div>
          <div class="card-body d-flex align-items-center justify-content-center" v-else>
            <b-spinner large></b-spinner>
          </div>
        </div>
      </div>
      <div v-else class="col-xl-2">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center">
              <b-dropdown
                  class="float-end"
                  variant="white"
                  right
                  menu-class="dropdown-menu-end"
                  toggle-class="font-size-16 text-body p-0">
                <template v-slot:button-content>
                  <i class="uil uil-ellipsis-v"></i>
                </template>
                <a class="dropdown-item"
                   href="javascript:void(0);"
                   @click="callModalEditGroup(groupInfo)">Edit</a>
                <a class="dropdown-item d-none" href="#">Remove</a>
              </b-dropdown>

              <i class="uil uil-angle-left float-start font-size-20 text-body btn p-0"
                 @click="toggle"></i>
              <div class="clearfix"></div>

              <h3 class="mt-3 mb-1">{{ groupInfo.name }}</h3>

              <div class="mt-2">

              </div>
            </div>

            <hr class="my-2" />

            <div class="table-responsive mt-4 mb-0">
              <div class="row">
                <div class="col-12 col-md-12 float-start">
                  <h5>Group Information</h5>
                </div>
              </div>
              <div class="mt-3">
                <p class="mb-2 text-strong">Description:</p>
                <p class="font-size-16 mb-2 justify-content-between">{{ groupInfo.description }}</p>
              </div>
              <hr class="bg-dark">
            </div>
            <div class="table-responsive mb-0">
              <div class="mt-3">
                <p class="mb-2 text-strong">Type:</p>
                <p class="font-size-16 mb-2 justify-content-between">{{ groupInfo.type }}</p>
              </div>
              <hr class="bg-dark">
              <div class="mt-3">
                <p class="mb-2 text-strong">Provider:</p>
                <p class="font-size-16 mb-2 justify-content-between">{{ groupInfo.provider }}</p>
              </div>
              <hr class="bg-dark">
              <div class="mt-3">
                <p class="mb-2 text-strong">Created By:</p>
                <p class="font-size-16 mb-2 justify-content-between">{{ groupInfo.created_by }}</p>
              </div>
              <hr class="bg-dark">
              <b-button v-if="groupInfo.type === 'static'" class="mb-2 d-block m-auto mwb-11" variant="danger" title="Delete Group" @click="removeGroup(groupInfo.group_id)"> Delete Group</b-button>
            </div>
          </div>
        </div>
      </div>
      <div :class="!toggleProfile ? 'col-xl-8' : 'col-xl-10'">
        <div class="card h-100">
          <div class="card-body" v-if="!showLoader" >
            <div class="col-12 col-md-12  float-start" v-if="groupInfo.provider === null || groupInfo.provider === ''">
              <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                 @click="callModalAddMembers()">
                <i class="mdi mdi-plus me-2"></i>Add Members
              </a>
            </div>
        <div>

          <b-table
              :items="tableData"
              :busy="isBusy"
              :fields="filteredFields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              show-empty
              empty-text="No Data Found"
          >

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template v-slot:cell(action)="tableData">
              <ul class="list-inline mb-0" v-if="groupInfo.type === 'static'">
                <li class="list-inline-item">
                  <a href="javascript:void(0);" class="px-2 text-danger" title="Delete Members" @click="removeMember(tableData.item.group_id, tableData.item.user_id)">
                    <i class="uil uil-trash-alt font-size-18"></i>
                  </a>
                </li>
              </ul>
            </template>


          </b-table>
        </div>
        <div class="row" v-show="filterData">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>

      </div>
      </div>
    </div>
    <!-- MODALS -->
    <modalEditGroup :groupData="modalData" @onRefresh="getGroupsDetails()"></modalEditGroup>
    <modalAddMembers  @onRefresh="getMembers"></modalAddMembers>

    <!-- END  MODALS -->

  </Layout>
</template>
